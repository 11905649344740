import { ReadMore } from '@components/read-more/ReadMore';
import { searchTextAtom } from '@components/reviews/components/search-sort-filter/atoms';
import MoreOrLess from '@xo-union/tk-component-more-or-less';
import { Body1 } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai/index';
import React, { type FC, memo, useCallback, useMemo, useState } from 'react';
import type VendorAnalytics from '../../../../../../decorators/vendorAnalytics';
import { useTrackReviewToggle } from './hooks/useTrackReviewToggle';
import Styles from './reviewBody.scss';

interface ContentProps {
	content: string;
	searchText?: string;
}

const Content: FC<ContentProps> = ({ content, searchText }) => {
	const result = useMemo(() => {
		if (searchText) {
			const regEx = new RegExp(`(\\b${searchText}\\b)`, 'gi');
			return (
				<>
					{content.split(regEx).map((text, index) => {
						const key = `${text}-${index}`;
						if (regEx.test(text))
							return (
								<mark key={key} className={Styles.highlighted}>
									{text}
								</mark>
							);
						return <span key={key}>{text}</span>;
					})}
				</>
			);
		}

		return content;
	}, [content, searchText]);

	return <>{result}</>;
};

interface ContentByLinesProps {
	content: string;
	expanded: boolean;
	setExpanded: (expanded: boolean) => void;
	onToggle: () => void;
	searchText: string | undefined;
}

const ContentByLines: FC<ContentByLinesProps> = memo(
	({ content, expanded, setExpanded, onToggle, searchText }) => {
		return (
			<ReadMore
				clamp
				expanded={expanded}
				linkColor="tertiary"
				setExpanded={setExpanded}
				onToggle={onToggle}
				classes={{
					container: Styles.readMoreContainer,
				}}
			>
				<Body1>
					<Content content={content} searchText={searchText} />
				</Body1>
			</ReadMore>
		);
	},
);

interface ContentCharactersProps {
	content: string;
	searchText: string | undefined;
}

const ContentCharacters: FC<ContentCharactersProps> = memo(
	({ content, searchText }) => {
		return (
			<div className={Styles.readMoreContainerInline}>
				<MoreOrLess content={content} charLimit={270}>
					<Body1>
						<Content content={content} searchText={searchText} />
					</Body1>
				</MoreOrLess>
			</div>
		);
	},
);

interface Props {
	content: string;
	vendorAnalytics?: VendorAnalytics;
	readMoreByLines?: boolean | null;
}

export const ReviewBody: FC<Props> = ({
	content,
	vendorAnalytics,
	readMoreByLines,
}) => {
	const [expanded, setExpanded] = useState(false);
	const trackReviewToggle = useTrackReviewToggle(vendorAnalytics);
	const searchText = useAtomValue(searchTextAtom);

	const onToggle = useCallback(() => {
		trackReviewToggle({ isTruncated: expanded });
	}, [expanded, trackReviewToggle]);

	return readMoreByLines ? (
		<ContentByLines
			content={content}
			expanded={expanded}
			setExpanded={setExpanded}
			onToggle={onToggle}
			searchText={searchText}
		/>
	) : (
		<ContentCharacters content={content} searchText={searchText} />
	);
};

export default ReviewBody;
