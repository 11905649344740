import React, { memo } from 'react';
import type { NavItemProps } from '../nav/types';
import VendorInfo from '../vendor_info';

interface VendorHeaderProps extends NavItemProps {
	isNavSticky?: boolean;
	isVendorInfoVisible?: boolean;
	vendor: Vendor.Decorated;
	awards: string[];
}

export const VendorHeader = memo(
	({
		vendor,
		awards,
		isVendorInfoVisible = true,
		isNavSticky = false,
	}: VendorHeaderProps) => {
		const {
			description,
			name,
			storefrontCard,
			priceRange,
			reviewsCount,
			stars,
		} = vendor;
		return (
			<div>
				{isVendorInfoVisible && <VendorInfo isNavSticky={isNavSticky} />}
				<meta itemProp="name" content={name} />
				<meta itemProp="description" content={description} />
				<meta itemProp="image" content={storefrontCard.url} />
				<meta itemProp="priceRange" content={priceRange} />
				{awards.map((award) => (
					<meta itemProp="award" content={award} key={award} />
				))}
				{reviewsCount > 0 ? (
					<div
						itemScope
						itemProp="aggregateRating"
						itemType="http://schema.org/AggregateRating"
					>
						<meta itemProp="ratingValue" content={stars.toString()} />
						<meta itemProp="reviewCount" content={reviewsCount.toString()} />
					</div>
				) : null}
			</div>
		);
	},
	(prevProps, nextProps) => {
		return prevProps.isNavSticky === nextProps.isNavSticky;
	},
);
