import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import Spacing from '@xo-union/tk-css-spacing/lib/variables.css';
import React, { type FC } from 'react';
import InlineRfqModal from '../InlineRFQ/components/InlineRfqModal/InlineRfqModal';
import { useInlineRfqForm } from '../InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import RFQButton from '../cta_box/RfqButton';

const MessageVendorCTA: FC = (props) => {
	const isInDetailedPricing = useDetailedPricing();

	const inlineRfqProps = isInDetailedPricing
		? { headerText: 'Start the convo' }
		: {};

	const formContext = useInlineRfqForm({
		initiator: 'Message Vendor CTA',
		...inlineRfqProps,
	});

	return (
		<>
			<InlineRfqModal context={formContext} />
			<RFQButton
				type="message"
				color="secondary"
				label="Message Vendor"
				styleName={Spacing['mt-up-2']}
				wrapStyle=""
				handleCTAClick={formContext.openModal}
				{...props}
			/>
		</>
	);
};

export default MessageVendorCTA;
