import { useTrackStorefrontImpression } from '@hooks/useTrackStorefrontImpression';
import type { ReviewAiSummaryResponseData } from '@typings/api/reviews';
import { Body2, Subhead } from '@xo-union/tk-ui-typography';
import React from 'react';
import { Sparkle } from './Sparkle';
import Styles from './styles.scss';
interface ReviewsAISummaryProps {
	data: ReviewAiSummaryResponseData;
}

const ReviewAISummary: React.FC<ReviewsAISummaryProps> = ({ data }) => {
	const impressionRef = useTrackStorefrontImpression('Review Summary');
	return (
		<div className={Styles.reviewAISummary} data-testid="review-ai-summary">
			<div className={Styles.reviewAISummaryTitle}>
				<Sparkle monochrome="black" size={'24'} />
				<Subhead ref={impressionRef} as="h2" bold>
					Review Summary
				</Subhead>
			</div>
			<Body2 className={Styles.reviewAISummarySubtitle}>
				This AI-generated summary is a snapshot of this vendor's ratings and
				feedback.
			</Body2>
			{data.review_summary}
		</div>
	);
};

export default ReviewAISummary;
